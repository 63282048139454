import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CaKIoANnI2 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import pwa_client_Eorgdr12jA from "/usr/src/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import vuetify_icons_RMzWu406ID from "/usr/src/app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import plugin_eTVJQYlCmx from "/usr/src/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "/usr/src/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import vuetify_YVjyb3BQvi from "/usr/src/app/plugins/lo/lincoln/vuetify.js";
import vuetify_OdPEdOST6f from "/usr/src/app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_CaKIoANnI2,
  components_plugin_KR1HBZs4kY,
  pwa_client_Eorgdr12jA,
  vuetify_icons_RMzWu406ID,
  plugin_eTVJQYlCmx,
  nuxt_plugin_03YjkxYbK5,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  vuetify_YVjyb3BQvi,
  vuetify_OdPEdOST6f
]