export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    _.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "dark",
        themes: {
          light: {},
          dark: {
            dark: true,
            colors: {
              main: "#000",
              leftarea: "#0c1929",
              appbar: "#0c1929",
              background: "#000204",
              surface: "#000000",
              rating: "#ec8a1a",
              button: "#6daffd",
              iconColor: "#9e9eff",
              yellowButton: "#e4cf04",
              breadcrumbs: "#FFFFFF",
              linkColor: "#8097ff",
            },
            variables: {
              "hover-opacity": 0,
              "focus-opacity": 0,
              "activated-opacity": 0,
            },
          },
        },
      },
    });
  });
});
